import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { AppStateService } from '../../core/app-state.service';
import { GoogleTagManagerService } from '../google/google-tag-manager.service';
import { Breadcrumb } from '../models/breadcrumb';
import { ServiceLocator } from '../service-locator.service';
import { AbstractPageForm } from './page-form.abstract';

export abstract class AbstractPage extends AbstractPageForm {
	protected titleService: Title;
	protected location: Location;
	protected googleTagManagerService: GoogleTagManagerService;

	constructor(
		public breadcrumbs: Breadcrumb[],
		pageTitle = 'Broker Portal',
	) {
		super();
		if (
			window.location.origin.includes('supplier.powerkiosk') ||
			window.location.origin.includes('suppliertest.powerkiosk') ||
			window.location.origin.includes('supplierdemo.powerkiosk')
		) {
			pageTitle = 'Supplier Portal';
		}
		this.titleService = ServiceLocator.injector.get(Title);
		this.location = ServiceLocator.injector.get(Location);
		this.googleTagManagerService = ServiceLocator.injector.get(GoogleTagManagerService);
		this.appStateService.setBreadcrumbs(breadcrumbs);
		this.titleService.setTitle(pageTitle);
	}

	set pageTitle(pageTitle: string) {
		this.titleService.setTitle(pageTitle);
	}

	get pageTitle() {
		return this.titleService.getTitle();
	}

	protected sendPageEvent(action: string): void {
		this.googleTagManagerService.sendPageEvent(action, this.loggedInUser, this.titleService.getTitle());
	}

	protected back(): void {
		this.location.back();
	}
}
